// externals
import * as React from 'react';

// libraries
import { OfferType } from '@makemydeal/dr-platform-types';

export interface IDisclaimerUIState {
    showShortDisclaimer: boolean;
}

export interface IDisclaimerUIProps {
    shortDisclaimerText: string;
    fullDisclaimerText: string;
    customDisclaimerText: string;
    paymentNotAvailable: boolean;
    onSeeDetailsClicked: { () };
    onHideDetailsClicked: { () };
    offerType: OfferType;
    tooltipCB: (top: string, viewDisclaimerLink: JSX.Element, tooltipContent: JSX.Element) => JSX.Element;
}

class DisclaimerUI extends React.Component<IDisclaimerUIProps, IDisclaimerUIState> {
    constructor(props: IDisclaimerUIProps) {
        super(props);
        this.state = {
            showShortDisclaimer: true
        };
        this.handleShowDetailsClick = this.handleShowDetailsClick.bind(this);
    }

    shouldShowSecondDisclaimer = () => {
        // Custom disclaimers hidden for ALL Cash Deals.
        return this.props.customDisclaimerText && this.props.offerType !== 'cash';
    };

    handleShowDetailsClick() {
        if (this.state.showShortDisclaimer) {
            this.props.onSeeDetailsClicked();
        } else {
            this.props.onHideDetailsClicked();
        }
        this.setState({
            showShortDisclaimer: !this.state.showShortDisclaimer
        });
    }

    render() {
        const { shortDisclaimerText, fullDisclaimerText, customDisclaimerText, paymentNotAvailable, tooltipCB } = this.props;
        const onClickHandler = this.handleShowDetailsClick;
        let className = 'disclaimer-footer-container';
        let displayText = 'Looks like we are having trouble calculating your payment.';
        let seeMoreDetails: any = null;
        const viewDisclaimerLink = (
            <div className="custom-disclaimer-link">
                <a className="disclaimer-summary-link">View Disclaimer</a>
            </div>
        );
        const tooltipContent = <div>{customDisclaimerText}</div>;

        if (!paymentNotAvailable) {
            displayText = this.state.showShortDisclaimer ? shortDisclaimerText : fullDisclaimerText;
            const detailsLinkText = this.state.showShortDisclaimer ? 'See Details.' : 'Hide Details.';
            seeMoreDetails = (
                <a
                    className="disclaimer-link"
                    onClick={() => {
                        onClickHandler();
                    }}
                >
                    {detailsLinkText}
                </a>
            );
        } else {
            className += ' not-available';
        }

        return (
            <div className={className}>
                {this.shouldShowSecondDisclaimer() && tooltipCB ? tooltipCB('top', viewDisclaimerLink, tooltipContent) : null}
                {displayText}
                {seeMoreDetails}
            </div>
        );
    }
}

export default DisclaimerUI;
