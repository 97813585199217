// externals
import * as React from 'react';
import { ReactNode } from 'react';

export interface IToggleButtonBase {
    id?: string;
    className?: string;
}

export interface IToggleButtonPanelProps {
    activeButtonIdOrClass: string | number;
    analyticsTag?: string;
    id?: string;
    className?: string;
    renderButton: { (item: IToggleButtonBase, tabClass: string): ReactNode };
    toggleButtons: IToggleButtonBase[];
}

export default class ToggleButtonPanelUI extends React.Component<IToggleButtonPanelProps, {}> {
    constructor(props) {
        super(props);
    }

    getTabClass(item: IToggleButtonBase) {
        const isSelected = item.id
            ? item.id === this.props.activeButtonIdOrClass
            : item.className === this.props.activeButtonIdOrClass;
        const tabClass = isSelected ? 'tab active' : 'tab inactive';
        return tabClass;
    }

    render() {
        const { toggleButtons, renderButton } = this.props;
        const toggleButtonsRendered = toggleButtons && toggleButtons.map((item) => renderButton(item, this.getTabClass(item)));
        const classSuffix = this.props.className ? ` ${this.props.className}` : '';
        const className = `toggle-button-panel-container${classSuffix}`;

        return (
            <div className="toggle-button-iewrap-container">
                <div className={className}>{toggleButtonsRendered}</div>
            </div>
        );
    }
}
