import * as React from 'react';
import { IconFactory } from '../IconFactory/IconFactory';
import { IConfigObj } from '@makemydeal/dr-common-utils';
import {
    NextStepCardRoot,
    NextStepsHeader,
    NextStepsHeaderText,
    RibbonContainer,
    RibbonText,
    TimeText,
    NextStepsBody,
    NextStepsTitle,
    NextStepsDescription,
    NextStepsButtonGroup,
    NextStepsButton
} from './NextStepsCardComponent';

export interface INextStepCardDetails {
    timeSaved: number;
    title: string;
    description: string;
    route: string;
    icon: string;
    name: string;
    nextStepsTitle?: string;
}

export interface ICardUIProps {
    staticImages: IConfigObj;
    cardDetails: INextStepCardDetails;
    rootClass?: string;
    onClick: { () };
}

function renderWithSuperscript(title) {
    const titleArr = (title || '').split('®');
    if (titleArr.length !== 2) return title;
    return (
        <>
            {titleArr[0]}
            <sup>®</sup>
            {titleArr[1]}
        </>
    );
}

export const NextStepCard: React.FC<ICardUIProps> = (props: ICardUIProps) => {
    const { cardDetails, staticImages, rootClass, onClick } = props;

    return (
        <NextStepCardRoot className={`next-steps-card ${rootClass || ''}`}>
            <NextStepsHeader className="next-steps-header">
                <NextStepsHeaderText className="next-steps-header-text common-header">Recommended Next Step</NextStepsHeaderText>
                <RibbonContainer className="ribbon-container">
                    <IconFactory staticImages={staticImages} icon="banner" className="next-steps-ribbon" />
                    <RibbonText className="ribbon-text">
                        <div>Save</div>
                        <TimeText className="time-text">{cardDetails.timeSaved}</TimeText>
                        <div>minutes</div>
                    </RibbonText>
                </RibbonContainer>
            </NextStepsHeader>
            <NextStepsBody className="next-steps-body">
                <NextStepsTitle className="next-steps-title common-header">
                    {renderWithSuperscript(cardDetails.title)}
                </NextStepsTitle>
                <NextStepsDescription className="next-steps-description">
                    {renderWithSuperscript(cardDetails.description)}
                </NextStepsDescription>
                <NextStepsButtonGroup className="next-steps-bottom-group">
                    <NextStepsButton
                        className="next-steps-start-button btn btn-primary"
                        data-route={cardDetails.route}
                        onClick={onClick}
                    >
                        Start
                    </NextStepsButton>
                    <IconFactory staticImages={staticImages} icon={cardDetails.icon} />
                </NextStepsButtonGroup>
            </NextStepsBody>
        </NextStepCardRoot>
    );
};
