import * as React from 'react';

import { BaseShareDealUI } from './ShareDealComponents';

const id = 'dealer-deal-panel';
const outerClassName = 'dealer-deal';
const header = 'Submit the Deal';
const description = 'Submit the completed deal to the Desking Manager for review';
const iconName = 'carCheckMark';
const linkLabel = 'Submit to Desk Manager';

const DealerDealUI = ({ staticImages, alignment, borderDirection, onClick }) => (
    <BaseShareDealUI
        {...{ staticImages, alignment, onClick, header, description, linkLabel, iconName, outerClassName, id, borderDirection }}
    />
);

export default DealerDealUI;
