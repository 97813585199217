import * as React from 'react';

import { BaseShareDealUI } from './ShareDealComponents';

const header = 'Save Your Progress';
const description = 'Use a custom link to return to your deal anytime, anywhere';
const linkLabel = 'Get My Deal Link';
const iconName = 'saveDeal';
const outerClassName = 'share-deal-save-panel';
const id = 'share-deal-panel';

const ShareDealUI = ({ staticImages, alignment, borderDirection, onClick }) => (
    <BaseShareDealUI
        {...{ staticImages, alignment, onClick, header, description, linkLabel, iconName, outerClassName, id, borderDirection }}
    />
);

export default ShareDealUI;
