import { BaseSelectors } from './baseSelectors';

export abstract class BaseMiddleware {
    constructor(public selector: BaseSelectors, public analyticsAction?: (store: any, key: string, desc: string) => void) {
        this.middleware = this.middleware.bind(this);
        this.callAnalytics = this.callAnalytics.bind(this);
    }

    middleware(_store: any) {
        return (next: any) => (action: any) => {
            next(action);

            // log analytics here
            this.middlewareFunction(_store, next, action);
        };
    }

    protected abstract middlewareFunction(store: any, next: any, action: any): any;

    protected callAnalytics(store: any, type: string, description: string) {
        if (typeof this.analyticsAction === 'function') {
            this.analyticsAction(store, type, description);
        }
    }
}
