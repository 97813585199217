import { rem } from 'polished';
import { Device } from '../../utils/style-util';
import styled from 'styled-components';

export const ActionFooterLink = styled.a`
    color: ${({ theme }) => theme.linkColor || '#0d65bf'};
`;
export const ActionFooterSubText = styled.div`
    text-align: center;
    margin-top: ${rem(24)};
    color: ${({ theme }) => theme.secondaryFontColor || '#666666'};
`;
export const ActionFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    .back-btn-below-action-btn {
        display: flex;
        flex-direction: column-reverse;
        .dr-action-footer-bar__back-link {
            padding-right: 0;
            margin-top: 1rem;
        }
    }
`;

export const ActionButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        min-width: ${rem(200)};
    }
    .dr-action-footer-bar__back-link,
    .dr-action-footer-bar-next-link {
        font-size: 1rem;
        cursor: pointer;
        padding-right: 1em;

        .fa-chevron-left {
            margin-right: ${rem(5)};
        }
    }

    .dr-action-footer-bar__back-link.disabled {
        opacity: 0.4;

        &:hover,
        a:hover {
            cursor: not-allowed;
        }
    }

    .dr-action-footer-bar__button_fixed_size {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 15vw;
        @media ${Device.tabletPortrait} {
            width: 25vw;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;
