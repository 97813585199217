import * as React from 'react';
import { NextStepCard } from '../../../components/NextStepsUI/NextStepCard';
import { CardContainer } from '../styledCardContainer';
import { IConfigObj } from '@makemydeal/dr-common-utils';

interface ICardUIProps {
    staticImages: IConfigObj;
    gotoNavRoute: { (route: string) };
    navFlow: any;
}

export const NextStepsCard: React.FC<ICardUIProps> = (props: ICardUIProps) => {
    const {
        staticImages,
        navFlow: { navCard, defaultRoute, name },
        gotoNavRoute
    } = props;

    const handleClick = () => gotoNavRoute(defaultRoute);

    const cardProps = {
        rootClass: 'dashboard-card',
        onClick: handleClick,
        staticImages,
        cardDetails: {
            timeSaved: navCard.timeSaved,
            title: navCard.title,
            description: navCard.nextStepsDescription,
            route: defaultRoute,
            icon: navCard.dashboardIcon,
            name
        }
    };

    return (
        <CardContainer>
            <div className="dashboard-card-container fadeInUp" data-name={name} data-testid="next-steps-card">
                <NextStepCard {...cardProps} />
            </div>
        </CardContainer>
    );
};
