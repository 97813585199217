import * as React from 'react';
import { Component } from 'react';
import { CircularProgress } from './CircularProgress';

export interface ISpinnerUIProps {
    loadingText?: string;
    size?: string;
}

class SpinnerUI extends Component<ISpinnerUIProps, {}> {
    constructor(props: ISpinnerUIProps) {
        super(props);
    }

    getLoadingText() {
        let loadingText;
        if (this.props.loadingText) {
            loadingText = <div className="loading-text">{this.props.loadingText}</div>;
        }
        return loadingText;
    }

    render() {
        const defaultProps = {
            loadingText: '',
            size: 40
        };
        return (
            <div className="dr-sp-spinner">
                {this.getLoadingText()}
                <div className="spinner">
                    <CircularProgress size={Number(this.props.size || defaultProps.size)} />
                </div>
            </div>
        );
    }
}

export default SpinnerUI;
