export enum NavigationItemStatus {
    DEFAULT = 0,
    USE_LEGACY_DEFAULT = 10,
    READY_TO_SEND = 20,
    SENT_FROM_DEALER = 21,
    ACCEPTED = 22,
    SENT = 30,
    CREDIT_LOCK = 40,
    UNAVAILABLE = 41,
    DISABLED_ON_CASH = 42,
    NOT_AVAILABLE_ONLINE = 43
}
