/*
 This component will replace ShopperEducationUI for use as a header component
 Currently used for Pre-Purchase agreement
 ToDo: Update the other pages to use this header component
*/

import * as React from 'react';
import { isEmpty } from '../../utils/isEmpty';

export interface ICardHeaderUIProps {
    id?: string;
    cardHeading?: string;
    secondaryText?: string;
    subHeader?: string;
    subHeaderSubtext?: string;
    isSummary?: boolean;
}

export interface ICardHeaderUIState {}

export default class CardHeaderUI extends React.Component<ICardHeaderUIProps, ICardHeaderUIState> {
    static defaultProps = {
        cardHeading: 'Card Heading Text',
        secondaryText: ''
    };

    constructor(props) {
        super(props);
    }

    getHeaderClass = () => {
        const { secondaryText, isSummary } = this.props;

        let headerClass = isSummary ? 'summary-header ' : 'header ';
        if (secondaryText) headerClass += 'header--withSubtext ';
        return headerClass;
    };

    render() {
        const { cardHeading, secondaryText, subHeader, isSummary, subHeaderSubtext } = this.props;

        const headerClassName = this.getHeaderClass();
        const cardHeadingHtml = cardHeading && <h1 className={headerClassName}>{cardHeading}</h1>;
        const subheaderSubTextClassName = subHeaderSubtext ? 'sub-header sub-header--withSubtext' : 'sub-header';
        const headerSubTextClassName = isSummary ? 'summary-secondary' : 'secondary';
        const headerSubTextHtml = secondaryText ? <div className={headerSubTextClassName}>{secondaryText}</div> : null;
        const subHeaderHtml = subHeader ? <div className={subheaderSubTextClassName}>{subHeader}</div> : null;
        const subHeaderSubTextHtml = subHeaderSubtext ? <p className="sub-header__subtext">{subHeaderSubtext}</p> : null;

        const showHeader = [cardHeading, secondaryText, subHeader, subHeaderSubtext].some((str) => !isEmpty(str));

        if (!showHeader) {
            return null;
        }
        return (
            <div className="dr-sp-card-header">
                {cardHeadingHtml}
                {headerSubTextHtml}
                {subHeaderHtml}
                {subHeaderSubTextHtml}
            </div>
        );
    }
}
