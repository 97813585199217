import styled from 'styled-components';
import { rem } from 'polished';

import { Device } from '../../utils/style-util';

export const ViewDealCardRoot = styled.div`
    color: ${({ theme }) => theme.viewDealCardRootColor};
    padding: ${rem(20)};
    border-radius: ${({ theme }) => theme.commonViewDealCardBorderRadius};
    box-shadow: ${({ theme }) => theme.commonViewDealCardBoxShadow};
    background-image: ${({ theme }) =>
        `linear-gradient(to top, ${theme.viewDealCardBackgroundGradientColor1}, ${theme.viewDealCardBackgroundGradientColor2})`};
    display: flex;
    flex-basis: 38%;
    flex-direction: column;
    justify-content: space-between;
    @media ${Device.phone} {
        align-items: center;
    }
`;
export const ViewDealHeader = styled.div`
    font-size: ${rem(18)};
    line-height: 1.33;
    color: ${({ theme }) => theme.viewDealHeaderColor};
    margin-bottom: 0.25rem;
`;
export const ViewDealBodyText = styled.p`
    font-size: ${rem(16)};
    line-height: 1.25;
    color: ${({ theme }) => theme.viewDealBodyTextColor};
    margin-bottom: 1rem;
`;

// TODO: KTLO story (DE95285) to get rid of height and bring in theming
export const ViewDealButtonComponent = styled.button`
    font-size: ${rem(16)};
    line-height: 1.25;
    margin-bottom: 1rem;
    min-width: 176px;
    height: 40px;
    border-radius: ${({ theme }) => theme.commonViewDealCardButtonBorderRadius};
    background-color: ${({ theme }) => theme.viewDealButtonBackgroundColor};
    font-weight: bold;
    color: ${({ theme }) => theme.viewDealButtonColor};
`;
