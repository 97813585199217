import * as React from 'react';
import numeral from 'numeral';

export interface IPriceBoxComponentProps {
    price: number;
    name: string;
    offerMonthlyPayment?: string;
}

class PriceBoxUI extends React.Component<IPriceBoxComponentProps, any> {
    constructor(props) {
        super(props);
    }

    roundDollar(dollars) {
        return Math.ceil(dollars);
    }

    formatDollars(dollars) {
        if (dollars === null || isNaN(dollars)) {
            return '$--';
        }

        return numeral(this.roundDollar(dollars)).format('$0,000');
    }

    render() {
        const { price, offerMonthlyPayment } = this.props;
        let priceBox = <div />;

        if (price) {
            priceBox = (
                <div className="price-box-cash-component">
                    <div>
                        Protect your vehicle for an estimated <span className="price-box-amount">{this.formatDollars(price)}</span>
                    </div>
                </div>
            );
        }

        if (offerMonthlyPayment) {
            priceBox = (
                <div className="monthly-price-box-component">
                    <div className="price-box-header">
                        Protect your vehicle for an estimated <strong>{offerMonthlyPayment}</strong>
                    </div>
                    <h6>Total protection cost of {this.formatDollars(price)} plus taxes and interest</h6>
                </div>
            );
        }

        return priceBox;
    }
}

export default PriceBoxUI;
