import * as React from 'react';
import ViewDealCard from '../ViewDealCard/ViewDealCardUI';
import { NextStepCard, INextStepCardDetails } from './NextStepCard';
import { IConfigObj } from '@makemydeal/dr-common-utils';
import { CardHeader, CardSubHeader, NextStepContainer } from './NextStepsUIComponents';

export interface INextStepsUIProps {
    id?: string;
    cardHeading?: string;
    subHeader?: string;
    goToRoute?: { (route: string) };
    renderCards?: { () };
    cardDetails?: INextStepCardDetails;
    staticImages?: IConfigObj;
    dispatchAnalytics?: { (type: string, description: string) };
}

export const NextStepsUI: React.FC<INextStepsUIProps> = (props) => {
    const handleRouteClick = () => {
        const { goToRoute, cardDetails, dispatchAnalytics } = props;

        if (!cardDetails) {
            return;
        }

        if (dispatchAnalytics) {
            const nextStep = cardDetails.name.toLocaleUpperCase();
            dispatchAnalytics('DR_NEXT_STEPS_CLICKED', `User clicked next steps for ${nextStep}`);
        }

        if (goToRoute) {
            goToRoute(cardDetails.route);
        }
    };

    const { cardDetails, cardHeading, dispatchAnalytics, goToRoute, renderCards, staticImages, subHeader } = props;
    const headingText = cardDetails && (cardDetails.nextStepsTitle || `Already have ${cardDetails.title}?`);

    return (
        <div className="dr-next-steps-card">
            <CardHeader className="card-header common-header">{cardHeading}</CardHeader>
            <CardSubHeader className="card-sub-header">{subHeader}</CardSubHeader>
            <NextStepContainer className="next-steps-container">
                {cardDetails && staticImages && (
                    <NextStepCard staticImages={staticImages} cardDetails={cardDetails} onClick={handleRouteClick} />
                )}
                {renderCards && renderCards()}
                <ViewDealCard dispatchAnalytics={dispatchAnalytics} goToRoute={goToRoute} headingText={headingText}></ViewDealCard>
            </NextStepContainer>
        </div>
    );
};
NextStepsUI.defaultProps = {
    cardHeading: 'What would you like to do next?',
    subHeader: 'Take the next step to save time before heading to the dealership.'
};
