import * as React from 'react';
import { Route } from 'react-router-dom';

const renderMergedProps = (component: any, ...rest: any[]) => {
    const finalProps = (Object as any).assign({}, ...rest);
    return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }: { [key: string]: any }) => {
    return (
        <Route
            {...rest}
            render={(routeProps) => {
                return renderMergedProps(component, routeProps, rest);
            }}
        />
    );
};

export default PropsRoute;
