import * as React from 'react';
import { ViewDealButtonComponent } from './ViewDealCardComponents';

export interface IViewDealButtonUIProps {
    applyInverseStyling?: boolean;
    buttonText?: string;
    dispatchAnalytics?: { (type: string, description: string) };
    goToRoute?: { (route: string) };
}

export const ViewDealButton: React.FC<IViewDealButtonUIProps> = (props) => {
    const handleViewSummaryClick = () => {
        const { goToRoute, dispatchAnalytics } = props;
        if (dispatchAnalytics) {
            dispatchAnalytics('DR_VIEW_DEAL_CLICKED', 'User returned to dashboard from [CURRENTROUTE]');
        }
        if (goToRoute) {
            goToRoute('/dashboard');
        }
    };
    const buttonText = props.buttonText || 'View My Deal';
    let className = 'view-deal-button btn btn-primary';
    if (props.applyInverseStyling) {
        className = `${className} btn-inverse`;
    }
    return (
        <ViewDealButtonComponent className={className} onClick={handleViewSummaryClick}>
            {buttonText}
        </ViewDealButtonComponent>
    );
};
