import * as React from 'react';

/**
 * Props
 */
export interface IPaymentErrorDispatchProps {
    retryClick: { () };
    sendFailedPayment?: { () };
}

export interface IPaymentErrorUIStateProps {
    message?: string;
    dontShowRetry?: boolean;
    pageId?: string;
    isError?: boolean;
}

export type PaymentErrorUIProps = IPaymentErrorUIStateProps & IPaymentErrorDispatchProps;

/**
 * State
 */
export interface IPaymentErrorUIState {}

export class PaymentErrorUI extends React.Component<PaymentErrorUIProps, IPaymentErrorUIState> {
    constructor(props) {
        super(props);
    }

    retry = () => {
        this.props.retryClick();
    };

    errorMessage = () => {
        const { message, dontShowRetry, isError } = this.props;

        if (isError) {
            if (this.props.pageId) {
                if (typeof this.props.sendFailedPayment === 'function') {
                    this.props.sendFailedPayment();
                }
            }
            if (message) {
                return (
                    <p className="payment-error-message danger">
                        {message}
                        {dontShowRetry ? (
                            ''
                        ) : (
                            <a id="payment-error-retry" onClick={this.retry}>
                                <b>retry</b>
                            </a>
                        )}
                    </p>
                );
            }
            return (
                <div className="payment-error-message danger">
                    {message}
                    <p>Looks like we are having trouble calculating your payment.</p>
                    <p>
                        Select another option or{' '}
                        <a id="payment-error-retry" onClick={this.retry}>
                            <b>retry</b>
                        </a>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    render() {
        return <div>{this.errorMessage()}</div>;
    }
}

export default PaymentErrorUI;
