import styled from 'styled-components';
import { rem } from 'polished';

import { Device } from '../../utils/style-util';

export const CardHeader = styled.h1`
    font-size: ${rem(24)};
    text-align: left;
    color: ${({ theme }) => theme.nextStepsHeaderColor};
    line-height: normal;
    margin: 0;
    margin-top: 3rem;
    @media ${Device.phone} {
        width: ${rem(360)};
        font-size: ${rem(20)};
    }
`;
export const CardSubHeader = styled.div`
    font-size: ${rem(18)};
    color: ${({ theme }) => theme.nextStepsSubHeaderColor};
    margin-bottom: ${rem(32)};
    @media ${Device.phone} {
        width: ${rem(327)};
        height: ${rem(44)};
    }
`;
export const NextStepContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    @media ${Device.phone} {
        display: block;
    }
`;
