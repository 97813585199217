// externals
import * as React from 'react';

// components
import { ViewDealCardRoot, ViewDealHeader, ViewDealBodyText } from './ViewDealCardComponents';
import { ViewDealButton } from './ViewDealButtonUI';

export interface IViewDealCardUIProps {
    bodyText?: string;
    buttonText?: string;
    dispatchAnalytics?: { (type: string, description: string) };
    goToRoute?: { (route: string) };
    headingText?: string;
}

export const ViewDealCard: React.FC<IViewDealCardUIProps> = (props) => {
    const headingText = props.headingText || 'Already have vehicle financing?';
    const bodyText = props.bodyText || 'There are more ways for you to save time and money.';
    const buttonText = props.buttonText || 'View My Deal';
    return (
        <ViewDealCardRoot className="view-deal-card">
            <ViewDealHeader className="view-deal-header common-header">{headingText}</ViewDealHeader>
            <ViewDealBodyText className="view-deal-body-text">{bodyText}</ViewDealBodyText>
            <ViewDealButton
                applyInverseStyling={true}
                buttonText={buttonText}
                dispatchAnalytics={props.dispatchAnalytics}
                goToRoute={props.goToRoute}
            />
        </ViewDealCardRoot>
    );
};
export default ViewDealCard;
