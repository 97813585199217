import * as React from 'react';
import { IconFactory } from '../../../components/IconFactory/IconFactory';
import { CardContainer } from '../styledCardContainer';
import { IConfigObj } from '@makemydeal/dr-common-utils';

export interface ICardUIProps {
    staticImages: IConfigObj;
    gotoNavRoute: { (route: string) };
    navFlow: any;
    showMobileCard: boolean;
}

export const NotStartedCard: React.FC<ICardUIProps> = (props: ICardUIProps) => {
    const {
        staticImages,
        showMobileCard,
        navFlow: { navCard, defaultRoute, name },
        gotoNavRoute
    } = props;
    const handleClick = (event) => gotoNavRoute(event.currentTarget.dataset.route);

    const mobileCard = (
        <div className="not-started-card-mobile dashboard-card" data-testid="not-started-card">
            <div className="not-started-header-mobile">
                <span className="card-title shift-title-mobile common-header">{navCard.title}</span>
                <span className="save-time">
                    Save <span className="time-saved">{navCard.timeSaved}</span> mins
                </span>
            </div>
            <div className="short-description">{navCard.mobileDescription}</div>
            <div className="not-started-footer-mobile">
                <button className="not-started-start-button btn btn-secondary" data-route={defaultRoute} onClick={handleClick}>
                    Start
                </button>
                <IconFactory staticImages={staticImages} icon={navCard.dashboardIcon} />
            </div>
        </div>
    );

    const desktopCard = (
        <div className="skinny-card not-started-card-desktop dashboard-card" data-testid="not-started-card">
            <div className="card-title not-started-body-item common-header">{navCard.title}</div>
            <IconFactory
                staticImages={staticImages}
                icon={navCard.dashboardIcon || 'carCheckMark'}
                className="not-started-body-item"
            />
            <div className="save-time">
                Save <span className="time-saved">{navCard.timeSaved}</span> mins
            </div>
            <button className="not-started-start-button btn btn-secondary" data-route={defaultRoute} onClick={handleClick}>
                Start
            </button>
        </div>
    );

    return (
        <CardContainer>
            <div data-name={name} className="dashboard-card-container skinny-card-container fadeInUp">
                {showMobileCard ? mobileCard : desktopCard}
            </div>
        </CardContainer>
    );
};
