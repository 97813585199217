import { useState, useEffect } from 'react';

/**
 * Hook for watching when the browser window is resized and returns height and width.
 */
const useWindowSize = () => {
    const getWindowDimensions = () => ({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowSize;
