import * as React from 'react';
import LoadingButtonUI from '../LoadingButton/LoadingButtonUI';
import { ActionFooterLink, ActionFooter, ActionButtonWrapper, ActionFooterSubText } from './ActionFooterBarComponents';

export interface IActionFooterBarProps {
    onActionButtonClick?: { (): any };
    onBackButtonClick?: { (): any };
    buttonText?: string;
    subText?: string;
    showBackButton: boolean;
    disableActionButton?: boolean;
    disableBackButton?: boolean;
    hideBackIcon?: boolean;
    backButtonText?: string;
    isPending?: boolean;
    hasSpinner?: boolean;
    fixedSizeForSpinner?: boolean;
    useLinkForActionButton?: boolean;
    backButtonBelowActionButton?: boolean;
}

/*
 * This is the action footer bar used on the bottom of (currently, as of 2018/May) Reservation and Accept Deal
 **/
export default class ActionFooterBar extends React.Component<IActionFooterBarProps> {
    constructor(props: IActionFooterBarProps) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    handleBackClick() {
        const { onBackButtonClick, disableBackButton } = this.props;
        if (onBackButtonClick && !disableBackButton) {
            onBackButtonClick();
        }
    }

    renderBackButton() {
        let backButton = <div></div>;
        const buttonText = this.props.backButtonText || 'Back';
        const icon = this.props.hideBackIcon ? '' : <i className="fa fa-chevron-left"></i>;

        if (this.props.showBackButton) {
            let backButtonClass = 'dr-action-footer-bar__back-link';
            if (this.props.disableBackButton) backButtonClass += ' disabled';
            backButton = (
                <div className={backButtonClass} onClick={this.handleBackClick}>
                    <ActionFooterLink id="action_footer_bar_back_link" className="action-footer-bar-back-link">
                        {icon}
                        {buttonText}
                    </ActionFooterLink>
                </div>
            );
        }
        return backButton;
    }

    renderActionButton() {
        let actionButton = <div></div>;
        const disable = this.props.disableActionButton || false;
        const hasSpinner = this.props.hasSpinner || false;
        const className =
            this.props.fixedSizeForSpinner && hasSpinner
                ? 'dr-action-footer-bar__button_fixed_size'
                : 'dr-action-footer-bar__button';

        if (typeof this.props.onActionButtonClick === 'function') {
            const fn = this.props.onActionButtonClick;
            actionButton = (
                <div className={className}>
                    {this.props.useLinkForActionButton ? (
                        <div className="dr-action-footer-bar-next-link" onClick={fn}>
                            <ActionFooterLink id="action_footer_bar_next_link" className="action-footer-bar-next-link">
                                {this.props.buttonText}
                            </ActionFooterLink>
                        </div>
                    ) : (
                        <LoadingButtonUI
                            buttonText={this.props.buttonText || ''}
                            disabled={disable}
                            isPending={!!(hasSpinner && this.props.isPending)}
                            id="action_footer_bar_action_button"
                            isSubmit={true}
                            onClick={fn}
                            additionalClassParams="btn-block action-footer__button"
                        />
                    )}
                </div>
            );
        }
        return actionButton;
    }

    render() {
        const { subText, backButtonBelowActionButton, showBackButton } = this.props;
        const actionWrapperClass = showBackButton && backButtonBelowActionButton ? 'back-btn-below-action-btn' : '';

        return (
            <ActionFooter className="dr-action-footer-bar">
                <ActionButtonWrapper className={actionWrapperClass}>
                    {this.renderBackButton()}
                    {this.renderActionButton()}
                </ActionButtonWrapper>
                {subText && <ActionFooterSubText>{subText}</ActionFooterSubText>}
            </ActionFooter>
        );
    }
}
