import { connect } from 'react-redux';

import { handleOpenDealerDealModal } from '../../store/navigation/actionCreators';
import DealerDealUI from './DealerDealUI';

const mapDispatchToProps = (dispatch) => ({
    onClick: () => {
        dispatch(handleOpenDealerDealModal());
    }
});

export default connect(undefined, mapDispatchToProps)(DealerDealUI);
