// externals
import * as React from 'react';

// components
import ToggleButtonPanelUI, { IToggleButtonBase } from '../ToggleButtonPanel/ToggleButtonPanelUI';

export interface IToggleButtonClickHandler {
    (buttonId: string);
}

export interface IToggleButtonInfo extends IToggleButtonBase {
    name: string;
    label: string;
}

export interface IToggleButtonCardProps {
    toggleButtonInfo: IToggleButtonInfo[];
    activeId: string | number;
    onClick: { (infoId: string) };
    id?: string;
    analyticsTag?: string;
    label?: string;
}

interface IToggleButtonCardState {
    activeId: string | number;
    initialActiveId?: string | number;
}

export default class ToggleButtonCardUI extends React.Component<IToggleButtonCardProps, IToggleButtonCardState> {
    state = {
        activeId: '',
        initialActiveId: ''
    };

    constructor(props) {
        super(props);

        // this.state = {
        //     activeId: this.props.activeId,
        //     initialActiveId: this.props.activeId
        // };
    }

    hydrateState = () => {
        if (this.state.initialActiveId !== this.props.activeId) {
            this.setState({
                activeId: this.props.activeId,
                initialActiveId: this.props.activeId
            });
        }
    }

    componentDidMount = () => {
        this.hydrateState();
    }

    componentDidUpdate = () => {
        this.hydrateState();
    }

    composeToggleButton = (itemBase: IToggleButtonBase, tabClass: string) => {
        const item = itemBase as IToggleButtonInfo;
        const { onClick } = this.props;
        const id: string = item.id as string;

        return (
            <div
                id={id}
                key={id}
                className={tabClass}
                onClick={() => {
                    if (onClick) {
                        this.setState({
                            activeId: id
                        });
                        onClick(id);
                    }
                }}
            >
                <div className="toggle-button-title">{item.name}</div>
                <div className="toggle-button-subtitle">{item.label}</div>
            </div>
        );
    };

    render() {
        const { toggleButtonInfo } = this.props;

        toggleButtonInfo.forEach(toggle => toggle.id = toggle.id || toggle.name);
        return (
            <ToggleButtonPanelUI
                activeButtonIdOrClass={this.state.activeId}
                analyticsTag={this.props.analyticsTag}
                className="toggle-button-container"
                id={this.props.id}
                renderButton={this.composeToggleButton}
                toggleButtons={toggleButtonInfo}
            />
        );
    }
}
