export class BaseSelectors {
    constructor(public sliceName?: string) {}

    /**
     * Get the state slice for this activity
     * @param state The overall state tree
     */
    getState(state: any): any {
        if (!this.sliceName) {
            return state || {};
        }
        return state[this.sliceName] || {};
    }
}
