import * as React from 'react';
import styled from 'styled-components';

export interface ICircularProgressProps {
    size?: number;
}

const SpinnerContainer = styled.div`
    display: inline-block;
    position: relative;
    width: ${(props) => `${props.size || 40}px`};
    height: ${(props) => `${props.size || 40}px`};
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${(props) => `${props.size || 40}px`};
        height: ${(props) => `${props.size || 40}px`};
        margin: 3px;
        border: 3px solid ${({ theme }) => theme.offerValueHeaderBackgroundColor || 'white'};
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${({ theme }) => theme.offerValueHeaderBackgroundColor || 'white'} transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const CircularProgress: React.FC<ICircularProgressProps> = ({ size }) => {
    return (
        <SpinnerContainer size={size} className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </SpinnerContainer>
    );
};
