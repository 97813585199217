import * as React from 'react';

/**
 *  This function is a custom hook to add "click outside" handling ie close a modal when clicking outside the modal.
 *  It can only be used at the top level of a React functional component.
 *
 * @param ref - ref of the "inside"; prevents clicks inside from closing
 * @param isActive - variable that controls when clickOutside event listener is on
 * @param handleClick - the function to call when clicking outside occurs
 *
 **/

const useClickOutside = (ref, isActive, handleClick) => {
    const handleClickOutside = (e) => {
        const target = e.target as Node;
        const getHelpExpanded = ref.current as HTMLDivElement;
        if (target !== getHelpExpanded && !getHelpExpanded.contains(target)) {
            handleClick();
        }
    };

    React.useEffect(() => {
        if (isActive) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isActive]);
};

export default useClickOutside;
