import * as React from 'react';

export interface IShopperEducationUIProps {
    id?: string;
    cardHeading?: string;
    secondaryText?: string;
    iconType?: string;
    secondaryHeader?: string;
}

export interface IShopperEducationUIState {}

export default class ShopperEducationUI extends React.Component<IShopperEducationUIProps, IShopperEducationUIState> {
    static defaultProps = {
        cardHeading: 'Card Heading Text',
        iconType: 'offer'
    };

    constructor(props) {
        super(props);
    }

    getIcon(iconType: string) {
        return (
            <div className="icon-container">
                <div className={`shopper-education-icon ${iconType}`}></div>
            </div>
        );
    }

    render() {
        const { cardHeading, secondaryText, secondaryHeader } = this.props;

        const getSecondaryHeader = () =>
            secondaryHeader ? (
                <div className="secondary-header">
                    <p>{secondaryHeader}</p>
                </div>
            ) : (
                ''
            );
        const getSecondaryText = () =>
            secondaryText ? (
                <div className="secondary">
                    <p> {secondaryText} </p>
                </div>
            ) : (
                ''
            );

        return (
            <div id="dr-sp-shopper-education" className="dr-sp-education">
                <div className="header">{cardHeading}</div>
                {getSecondaryHeader()}
                {getSecondaryText()}
            </div>
        );
    }
}
